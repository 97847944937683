export enum LocationPaths {
  Auth = '/auth',
  SignIn = '/sign-in',
  PasswordResetRequest = '/password-reset-request',
  NotFound = '/not-found',
  DownloadApp = '/download-app',
  VideoCalls = '/video-calls',
  HealthQuestionnaire = '/health-questionnaire',
  Questionnaire = '/questionnaire/:surveyKind',
  SensorSelection = '/sensor-selection',
  FreeVideoCall = '/free-video-call',
  ScheduleCall = '/schedule-call',
  Dpp = '/dpp',
  Glp = '/care',
  OwnSensor = '/own-sensor',
  Checkout = 'checkout/:product',
  Courses = '/courses/:cohortKind'
}

export enum CoursesPath {
  Intro = 'intro',
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  JoinCohort = 'join',
  CohortJoined = 'joined',
  Dashboard = 'dashboard'
}

export enum VideoCallsPath {
  Insurance = 'insurance',
  EmailAddress = 'email-address',
  SignUp = 'sign-up',
  State = 'state',
  AddressForm = 'address-form',
  Completed = 'completed',
  AddPaymentMethod = 'add-payment-method',
  Questionnaire = 'questionnaire/:surveyKind',
  FreeVideoCall = 'free-video-call',
  ScheduleCall = 'schedule-call',
  AddAppointmentAgenda = 'add-appointment-agenda',
  HeardAboutNutrisense = 'heard-about-nutrisense'
}

export enum OwnSensorPath {
  ChooseHealthGoal = 'choose-health-goal',
  WeightLossQuote = 'weight-loss-quote',
  MobileOperatingSystem = 'mobile-operating-system',
  ChooseSensor = 'choose-sensor',
  EligibilityCheck = 'eligibility-check',
  EligibilityCheckRejection = 'eligibility-check-rejection',
  CreateAccount = 'create-account',
  PlanSelection = 'plan-selection',
  Checkout = 'checkout/:product',
  Success = 'success'
}

export enum DppPath {
  Chat = 'chat',
  Dashboard = 'dashboard',
  Completed = 'completed',
  JoinCohort = 'cohorts/join',
  CohortWaitlist = 'cohorts/waitlist',
  Ineligible = 'ineligible',
  IneligibleVideoCalls = 'ineligible/video-calls'
}

export enum GlpPath {
  Goal = 'goal',
  TriedLoseWeight = 'tried-lose-weight',
  NewApproach = 'new-approach',
  State = 'state',
  DateOfBirth = 'date-of-birth',
  MedicalHistory = 'medical-history',
  Bmi = 'bmi',
  Descent = 'descent',
  Conditions = 'conditions',
  WeightLossAmount = 'weight-loss-amount',
  Ineligible = 'ineligible',
  NotRightProgram = 'not-right-program',
  BmiTooLow = 'bmi-too-low',
  SignUp = 'sign-up',
  MissingInformation = 'missing-information',
  EligibilityNextSteps = 'eligibility-next-steps',
  Shipping = 'shipping',
  Checkout = 'checkout',
  PaymentSuccessful = 'payment-successful',
  Dashboard = 'dashboard',
  ScheduleCall = 'schedule-call',
  CallRescheduledCanceled = 'schedule-rescheduled-canceled',
  CallScheduled = 'call-scheduled',
  AllChats = 'all-chats',
  Chat = 'chat',
  Account = 'account'
}

export interface LocationState {
  from: LocationStateFrom
}

export interface LocationStateFrom {
  pathname: LocationPaths
}

export enum SearchParams {
  ChatProviderType = 'chatProviderType',
  CoachId = 'coachId',
  ConversationId = 'conversationId',
  ForceAllowCaptcha = 'force_allow_captcha',
  Outcome = 'outcome',
  Partner = 'partner',
  PendingAppointmentId = 'pendingAppointmentId',
  PreviousSlotNotAvailable = 'previousSlotNotAvailable',
  SchedulingSource = 'source',
  SensorOptions = 'sensorOptions',
  SurveyFallbackUrl = 'fallback',
  SurveyKind = 'survey_kind',
  TargetPath = 'target_path',
  Token = 'token',
  WebsiteSourceUrl = 'websiteSourceUrl',
  ExistingUserEmail = 'existingUserEmail'
}
