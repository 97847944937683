import { useState, useEffect, useCallback } from 'react'

export const useLocalStorage = (key: string) => {
  const [value, originalSetValue] = useState<string | null | undefined>(() =>
    localStorage.getItem(key)
  )

  useEffect(() => {
    const listener = () => {
      originalSetValue(localStorage.getItem(key))
    }

    window.addEventListener('storage', listener)

    originalSetValue(localStorage.getItem(key))

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [key])

  const setValue = useCallback(
    (value: string) => {
      localStorage.setItem(key, value)
      originalSetValue(value)
      window.dispatchEvent(new Event('storage'))
    },
    [key]
  )

  return [value, setValue] as const
}
