import { Layout, VideoCallFlowStep } from 'components/VideoCallFlowStep'
import { LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'
import { getVideoCallFlow } from 'screens/VideoCalls/types'

export const buildVideoCallsRoutes: () => RouteObject = () => ({
  path: LocationPaths.VideoCalls,
  element: <Layout />,
  children: getVideoCallFlow().map((step, index) => ({
    index: index === 0,
    path: step.path,
    element: <VideoCallFlowStep stepIndex={index} />,
    handle: { index }
  }))
})
