import { PrivateRoute } from 'components'
import { PageLayout } from 'components/PageLayout'
import { LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

export const root: RouteObject = {
  element: <PageLayout />,
  children: [
    {
      path: LocationPaths.Questionnaire,
      lazy: async () => ({ Component: (await import('screens/Survey')).SurveyScreen })
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: LocationPaths.DownloadApp,
          lazy: async () => ({
            Component: (await import('screens/Shared/DownloadApp')).DownloadApp
          })
        },
        {
          path: LocationPaths.HealthQuestionnaire,
          lazy: async () => ({ Component: (await import('screens/Survey')).SurveyScreen })
        },
        {
          path: LocationPaths.SensorSelection,
          lazy: async () => ({
            Component: (await import('screens/SensorSelection')).SensorSelection
          })
        },
        {
          path: LocationPaths.FreeVideoCall,
          lazy: async () => ({ Component: (await import('screens/FreeVideoCall')).FreeVideoCall })
        },
        {
          path: LocationPaths.ScheduleCall,
          lazy: async () => ({ Component: (await import('screens/ScheduleCall')).ScheduleCall })
        },
        {
          path: LocationPaths.Checkout,
          lazy: async () => ({ Component: (await import('screens/Checkout')).Checkout })
        }
      ]
    }
  ]
}
