import { ReactNode, useEffect, useState, createContext, useContext } from 'react'
import { Stripe, StripeElements, StripeElementsOptionsMode } from '@stripe/stripe-js'
import { elementsOptions, paymentElementOptions, stripePromise } from 'utils/stripe'

interface ElementsContextValue {
  stripe: Stripe | null
  elements: StripeElements | null
  loadStripe?: () => void
}

const ElementsContext = createContext<ElementsContextValue>({
  stripe: null,
  elements: null
})

export const Elements = ({ children }: { children: ReactNode }) => {
  const [shouldLoadStripe, setShouldLoadStripe] = useState(false)

  const loadStripe = () => {
    setShouldLoadStripe(true)
  }

  const [context, setContext] = useState<ElementsContextValue>({
    stripe: null,
    elements: null,
    loadStripe
  })

  useEffect(() => {
    if (shouldLoadStripe) {
      stripePromise.then((stripe) => {
        const elements = stripe?.elements(elementsOptions as StripeElementsOptionsMode) || null
        elements?.create('payment', paymentElementOptions)
        setContext({ stripe, elements })
      })
    }
  }, [shouldLoadStripe])

  return <ElementsContext.Provider value={context}>{children}</ElementsContext.Provider>
}

export const useStripe = () => {
  const { stripe } = useContext(ElementsContext)
  return stripe
}

export const useElements = () => {
  const { elements } = useContext(ElementsContext)
  return elements
}

export const useLoadStripe = () => {
  const { loadStripe } = useContext(ElementsContext)
  return loadStripe
}
