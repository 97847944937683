import { useMemo } from 'react'
import { NotFound } from 'components/NotFound'
import { LocationPaths } from 'location.types'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PARTNER_ID_KEY } from 'screens/VideoCalls/hooks'
import { useLocalStorage } from 'utils/localStorage'
import { UserReferralPartner } from 'types'
import { App } from './App'
import { auth, dpp, glp, ownSensor, root, buildVideoCallsRoutes } from './routes'
import { courses } from './routes/courses'

export const useAppRouter = () => {
  const [partner] = useLocalStorage(PARTNER_ID_KEY)
  const isInfluencerFlow = partner === UserReferralPartner.Influencer

  const router = useMemo(() => {
    return createBrowserRouter([
      {
        path: '/',
        element: <App />,
        children: [
          {
            index: true,
            element: <Navigate to={LocationPaths.DownloadApp} replace />
          },
          ...auth,
          dpp,
          glp,
          courses,
          buildVideoCallsRoutes(),
          ownSensor,
          root,
          {
            path: '*',
            element: <NotFound />
          }
        ]
      }
    ])
    // video call routes depend on partner, so we need to re-create the router when partner changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInfluencerFlow])

  return router
}
