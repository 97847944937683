import { PrivateRoute } from 'components'
import { PageLayout } from 'components/PageLayout'
import { DppPath, LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

export const dpp: RouteObject = {
  path: LocationPaths.Dpp,
  element: <PageLayout />,
  children: [
    {
      index: true,
      lazy: async () => ({ Component: (await import('screens/Dpp/Redirect')).Redirect })
    },
    {
      path: DppPath.Ineligible,
      lazy: async () => ({
        Component: (await import('screens/Dpp/NotEligibleOutcome')).NotEligibleOutcome
      })
    },
    {
      path: DppPath.IneligibleVideoCalls,
      lazy: async () => ({
        Component: (await import('screens/Dpp/NotEligibleVideoCallsOutcome'))
          .NotEligibleVideoCallsOutcome
      })
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: DppPath.Chat,
          lazy: async () => ({ Component: (await import('screens/Dpp/Chat')).Chat })
        },
        {
          path: DppPath.Dashboard,
          lazy: async () => ({ Component: (await import('screens/Dpp/Dashboard')).Dashboard })
        },
        {
          path: DppPath.Completed,
          lazy: async () => ({
            Component: (await import('screens/Dpp/FlowCompleted')).FlowCompleted
          })
        },
        {
          path: DppPath.JoinCohort,
          lazy: async () => ({
            Component: (await import('screens/Dpp/Cohorts/JoinCohort')).JoinCohort
          })
        },
        {
          path: DppPath.CohortWaitlist,
          lazy: async () => ({ Component: (await import('screens/Dpp/Cohorts/Waitlist')).Waitlist })
        }
      ]
    }
  ]
}
