import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Loader } from 'components/Loader'
import { Header } from 'components/PageLayout/Header'
import { LocationPaths } from 'location.types'
import { Outlet, useNavigate } from 'react-router-dom'
import { FlowProgress } from 'screens/OwnSensor/FlowProgress'
import { useFindCurrentStep } from 'screens/OwnSensor/hooks/useFindCurrentStep'
import { OwnSensorFlow } from 'screens/OwnSensor/Steps'
import { useCurrentUserQuery } from 'types'

const Loading = () => {
  return (
    <Box style={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}

export const Navigation = () => {
  const navigate = useNavigate()
  const { index: stepIndex, step } = useFindCurrentStep()
  const { data: { currentUser } = {}, loading: fetchingUser } = useCurrentUserQuery()

  useEffect(() => {
    if (fetchingUser) {
      return
    }

    if (currentUser?.ongoingCoreSubscription) {
      navigate(`${LocationPaths.DownloadApp}`)
    }
  }, [currentUser, fetchingUser, navigate])

  const goToPreviousStep = () => {
    if (!step.canNavigateBack) {
      return
    }

    const previousStepIndex = stepIndex - 1
    const previousStep = OwnSensorFlow[previousStepIndex]
    navigate(previousStep.location)
  }

  if (fetchingUser) {
    return <Loading />
  }

  return (
    <Box sx={styles.sticky}>
      <Header hideBorder {...(step.canNavigateBack && { backButton: goToPreviousStep })} />
      <FlowProgress stepIndex={stepIndex} />
      <Outlet />
    </Box>
  )
}

const styles = {
  sticky: {
    backgroundColor: 'members.background',
    position: 'sticky',
    zIndex: 100,
    top: 0
  }
}
