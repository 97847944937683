import { Box, LinearProgress, linearProgressClasses } from '@mui/material'
import { OwnSensorFlow } from './Steps'

interface FlowProgressProps {
  stepIndex: number
}

export const FlowProgress = (props: FlowProgressProps) => {
  const { stepIndex } = props
  const totalStepsCount = OwnSensorFlow.length
  let progress = (stepIndex + 1) / totalStepsCount
  // Ensure that progress can't be greater than 1 so the progress bar doesn't overflow
  progress = Math.min(progress, 1)

  return (
    <Box>
      <LinearProgress variant="determinate" value={progress * 100} sx={styles.progressBar} />
    </Box>
  )
}

const styles = {
  progressBar: {
    height: '6px',
    width: '100%',
    backgroundColor: '#E3E1DE',
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: 'brandPrimary.light.main',
      borderBottomRightRadius: 5,
      borderTopRightRadius: 5
    }
  }
}
