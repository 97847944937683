import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  LatestUserAppointment,
  useCurrentUserQuery,
  useLatestUserAppointmentQuery,
  User
} from 'types'
import { Direction } from '../Animated/types'
import { getVideoCallFlow } from '../types'
import { findNextStep } from '../utils'

export const useStepNavigation = (currentStep: number) => {
  const navigate = useNavigate()
  const [direction, setDirection] = useState(Direction.Forward)

  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { latestUserAppointment } = {} } = useLatestUserAppointmentQuery()

  const navigateToStep = useCallback(
    (stepChange: number) => {
      const targetStep = getVideoCallFlow()[currentStep + stepChange]
      if (!targetStep) {
        return
      }

      return setTimeout(() => navigate(targetStep.location), 200)
    },
    [currentStep, navigate]
  )

  const navigateToNextStep = () => {
    const nextStep = findNextStep(
      currentStep,
      currentUser as User,
      latestUserAppointment as LatestUserAppointment
    )

    setDirection(Direction.Forward)
    navigate(nextStep.location)
  }

  const navigateToPreviousStep = () => {
    setDirection(Direction.Backward)
    return navigateToStep(-1)
  }

  return {
    direction,
    setDirection,
    navigateToPreviousStep,
    navigateToNextStep
  }
}
