import { PrivateRoute } from 'components'
import { CoursesPath, LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'
import { Layout } from 'screens/Courses/Layout'

export const courses: RouteObject = {
  path: LocationPaths.Courses,
  element: <Layout />,
  children: [
    {
      index: true,
      lazy: async () => ({ Component: (await import('screens/Courses/Redirect')).Redirect })
    },
    {
      path: CoursesPath.Intro,
      lazy: async () => ({
        Component: (await import('screens/Courses/Intro')).Intro
      })
    },
    {
      path: CoursesPath.SignIn,
      lazy: async () => ({
        Component: (await import('screens/Courses/SignIn')).SignIn
      })
    },
    {
      path: CoursesPath.SignUp,
      lazy: async () => ({
        Component: (await import('screens/Courses/SignUp')).SignUp
      })
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: CoursesPath.JoinCohort,
          lazy: async () => ({
            Component: (await import('screens/Courses/JoinCohort')).JoinCohort
          })
        },
        {
          path: CoursesPath.CohortJoined,
          lazy: async () => ({
            Component: (await import('screens/Courses/CohortJoined')).CohortJoined
          })
        },
        {
          path: CoursesPath.Dashboard,
          lazy: async () => ({
            Component: (await import('screens/Courses/Dashboard')).Dashboard
          })
        }
      ]
    }
  ]
}
