import { Suspense, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { Box, Grid, Stack } from '@mui/material'
import { Typography } from '@mui/material'
import { Loader } from 'components'
import LockIcon from 'components/Icons/LockIcon'
import { Header } from 'components/PageLayout/Header'
import { preloadQuery } from 'config/apollo/client'
import { useLoadStripe } from 'context/StripeElementsContext'
import { usePageTracking } from 'hooks/analytics'
import { LocationPaths } from 'location.types'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Direction } from 'screens/VideoCalls/Animated'
import { VideoCallFlowContext } from 'screens/VideoCalls/context/VideoCallFlowContext'
import { FlowProgress } from 'screens/VideoCalls/FlowProgress'
import {
  useReferredByCoach,
  useStepNavigation,
  useFindCurrentStep,
  useReferralPartner
} from 'screens/VideoCalls/hooks'

import { GlobalTimer } from 'screens/VideoCalls/ReservationTimer'
import { findNextStep } from 'screens/VideoCalls/utils'
import { palette } from 'theme/theme.palette'
import {
  useCurrentUserQuery,
  useLatestUserAppointmentQuery,
  User,
  LatestUserAppointment,
  MobileAppFeature
} from 'types'

const Loading = () => {
  return (
    <Box style={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}

export const Layout = () => {
  const location = useLocation()
  const { index: stepIndex, step } = useFindCurrentStep()

  const { data: { currentUser } = {}, loading: loadingUser } = useCurrentUserQuery()
  const { data: { latestUserAppointment } = {}, loading: loadingAppointment } =
    useLatestUserAppointmentQuery()

  const { navigateToNextStep, navigateToPreviousStep, direction, setDirection } =
    useStepNavigation(stepIndex)
  const [callback, setCallback] = useState<(() => void) | null>(null)

  const currentStep = step
  const loading = loadingUser || loadingAppointment

  const trackPage = usePageTracking()
  const navigate = useNavigate()
  const { capturePartner } = useReferralPartner()
  const { captureCoachId } = useReferredByCoach()

  const loadStripe = useLoadStripe()

  useEffect(() => {
    if (loading) {
      return
    }
    const nextStep = findNextStep(
      stepIndex,
      currentUser as User,
      latestUserAppointment as LatestUserAppointment
    )

    if (nextStep && nextStep.routeComponent.preload) {
      nextStep.routeComponent.preload()
    }

    if (nextStep?.requiresStripe) {
      loadStripe?.()
    }

    if (nextStep.preloadQueries) {
      nextStep.preloadQueries.forEach((query) => preloadQuery(query))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, loading])

  useEffect(() => {
    if (!currentStep) {
      Bugsnag.notify(new Error('Invalid step index'))
      navigate(LocationPaths.NotFound)
    }
  }, [currentStep, navigate])

  useEffect(() => {
    trackPage(location.pathname)
  }, [location.pathname, trackPage])

  useEffect(() => {
    const currentStepCompleted = currentStep.completedResolver(
      currentUser as User,
      latestUserAppointment as LatestUserAppointment
    )

    if (!loading && currentStepCompleted) {
      const nextStep = findNextStep(
        stepIndex,
        currentUser as User,
        latestUserAppointment as LatestUserAppointment
      )

      navigate(nextStep.location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, latestUserAppointment])

  if (stepIndex === 0) {
    capturePartner()
    captureCoachId()
  }

  const registerCallback = (callback: () => void) => {
    setCallback(() => callback)
  }

  const handlePreviousStepNavigate = () => {
    if (callback) {
      callback()
      setCallback(null)
    }
    navigateToPreviousStep()
  }

  const alreadyEligible = currentUser?.features.includes(
    MobileAppFeature.InsuranceCoveredVideoCalls
  )

  // Hide the back button for users that are already eligible (i.e. users that became eligible via a different flow, e.g. DPP).
  // Otherwise, they will be able to navigate back to steps that don't make sense for them (e.g. the insurance survey)
  //
  const backButton =
    !alreadyEligible && (currentStep.canNavigateBack || !!callback)
      ? handlePreviousStepNavigate
      : undefined

  return (
    <Grid
      container
      sx={{
        flexWrap: 'nowrap',
        backgroundColor: palette.members.background
      }}
    >
      <Grid flex={1} item>
        <Stack minHeight="100dvh">
          <VideoCallFlowContext.Provider
            value={{
              stepIndex,
              navigateToNextStep,
              registerCallback,
              direction,
              resetBackDirection: () => {
                setDirection(Direction.Forward)
              }
            }}
          >
            <Box sx={styles.sticky}>
              <Header hideBorder backButton={backButton} />
              <FlowProgress />
            </Box>
            <GlobalTimer />
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>

            <Typography sx={styles.securityNote} variant="subtitle2">
              <LockIcon sx={styles.lockIcon} />
              All details you share are secure and confidential
            </Typography>
          </VideoCallFlowContext.Provider>
          {/* </AnimatePresence> */}
        </Stack>
      </Grid>
    </Grid>
  )
}

const styles = {
  securityNote: {
    color: 'brandText.light.main',
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.15,
    fontWeight: 400,
    width: '100%',
    paddingBottom: 2,
    paddingTop: 1,
    textAlign: 'center',
    marginTop: 'auto'
  },
  lockIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1rem',
    marginRight: 1
  },
  loaderWrapper: {
    margin: 'auto 0'
  },
  sticky: {
    backgroundColor: 'members.background',
    position: 'sticky',
    zIndex: 100,
    top: 0
  }
}
