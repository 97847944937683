import { FC, useEffect } from 'react'
import { Box } from '@mui/system'
import { Loader } from 'components'
import { useAuth } from 'context'
import { usePageTracking } from 'hooks/analytics'
import { useLocation, Outlet } from 'react-router-dom'

export const PrivateRoute: FC<any> = () => {
  const { isAuthorized, loading } = useAuth()
  const trackPage = usePageTracking()
  const location = useLocation()

  useEffect(() => {
    trackPage(location.pathname)
  }, [location, trackPage])

  if (loading || !isAuthorized) {
    return (
      <Box sx={{ height: '100vh' }}>
        <Loader />
      </Box>
    )
  }

  return <Outlet />
}
