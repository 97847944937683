import { DocumentNode } from 'graphql'
import { LocationPaths, OwnSensorPath } from 'location.types'
import { LazyComponentWithPreload, lazyPreload } from 'utils/lazyPreload'

export interface OwnSensorFlowStep {
  path: LocationPaths | OwnSensorPath
  location: string
  canNavigateBack: boolean
  routeComponent: LazyComponentWithPreload<() => JSX.Element | null>
  noPageAnimation?: boolean
  preloadQueries?: DocumentNode[]
}

const OwnSensorChooseHealthGoalScreen = lazyPreload(() =>
  import('screens/OwnSensor/ChooseHealthGoal').then((module) => ({
    default: module.ChooseHealthGoal
  }))
)

const OwnSensorMobileOperatingSystemScreen = lazyPreload(() =>
  import('screens/OwnSensor/MobileOperatingSystem').then((module) => ({
    default: module.MobileOperatingSystem
  }))
)

const OwnSensorChooseSensorScreen = lazyPreload(() =>
  import('screens/OwnSensor/ChooseSensor').then((module) => ({
    default: module.ChooseSensor
  }))
)

const OwnSensorCreateAccountScreen = lazyPreload(() =>
  import('screens/OwnSensor/CreateAccount').then((module) => ({
    default: module.CreateAccount
  }))
)

const OwnSensorPlanSelectionScreen = lazyPreload(() =>
  import('screens/OwnSensor/PlanSelection').then((module) => ({
    default: module.PlanSelection
  }))
)

const OwnSensorCheckoutScreen = lazyPreload(() =>
  import('screens/OwnSensor/Checkout').then((module) => ({
    default: module.Checkout
  }))
)

const OwnSensorSuccessScreen = lazyPreload(() =>
  import('screens/OwnSensor/Success').then((module) => ({
    default: module.Success
  }))
)

export const OwnSensorFlow: OwnSensorFlowStep[] = [
  {
    path: LocationPaths.OwnSensor,
    location: LocationPaths.OwnSensor,
    canNavigateBack: false,
    routeComponent: OwnSensorChooseHealthGoalScreen
  },
  {
    path: OwnSensorPath.MobileOperatingSystem,
    location: `${LocationPaths.OwnSensor}/${OwnSensorPath.MobileOperatingSystem}`,
    canNavigateBack: true,
    routeComponent: OwnSensorMobileOperatingSystemScreen
  },
  {
    path: OwnSensorPath.ChooseSensor,
    location: `${LocationPaths.OwnSensor}/${OwnSensorPath.ChooseSensor}`,
    canNavigateBack: true,
    routeComponent: OwnSensorChooseSensorScreen
  },
  {
    path: OwnSensorPath.CreateAccount,
    location: `${LocationPaths.OwnSensor}/${OwnSensorPath.CreateAccount}`,
    canNavigateBack: true,
    routeComponent: OwnSensorCreateAccountScreen
  },
  {
    path: OwnSensorPath.PlanSelection,
    location: `${LocationPaths.OwnSensor}/${OwnSensorPath.PlanSelection}`,
    canNavigateBack: false,
    routeComponent: OwnSensorPlanSelectionScreen
  },
  {
    path: OwnSensorPath.Checkout,
    location: `${LocationPaths.OwnSensor}/${OwnSensorPath.Checkout}`,
    canNavigateBack: true,
    routeComponent: OwnSensorCheckoutScreen
  },
  {
    path: OwnSensorPath.Success,
    location: `${LocationPaths.OwnSensor}/${OwnSensorPath.Success}`,
    canNavigateBack: false,
    routeComponent: OwnSensorSuccessScreen
  }
]
