import { useMatches } from 'react-router-dom'
import { OwnSensorFlow } from '../Steps'

export const useFindCurrentStep = () => {
  const matches = useMatches()

  const currentStepIdx = matches.slice(-1)[0]?.handle?.index

  if (!currentStepIdx) {
    return {
      index: 0,
      step: OwnSensorFlow[0]
    }
  }

  return {
    index: currentStepIdx,
    step: OwnSensorFlow[currentStepIdx]
  }
}
