import { useMatches } from 'react-router-dom'
import { getVideoCallFlow } from '../types'

export const useFindCurrentStep = () => {
  const matches = useMatches()

  const videoCallFlow = getVideoCallFlow()

  const currentStepIdx = matches.slice(-1)[0]?.handle?.index

  if (!currentStepIdx) {
    return {
      index: 0,
      step: videoCallFlow[0]
    }
  }

  return {
    index: currentStepIdx,
    step: videoCallFlow[currentStepIdx]
  }
}
