import { loadStripe, StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js'
import { env } from 'config'
import { palette } from 'theme/theme.palette'

export const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')

export const elementsOptions: StripeElementsOptions = {
  mode: 'setup',
  currency: 'usd',
  loader: 'never',
  payment_method_types: ['card'],
  appearance: {
    variables: {
      colorPrimary: palette.members.primary,
      focusBoxShadow: `0px 0px 0px 3px ${palette.members.shadow}`
    },
    rules: {
      '.Label': {
        fontSize: '0'
      },
      '.Input': {
        boxShadow: 'none'
      }
    }
  }
}

export const paymentElementOptions: StripePaymentElementOptions = {
  fields: {
    billingDetails: {
      address: {
        country: 'never',
        postalCode: 'never'
      }
    }
  },
  terms: {
    card: 'never'
  }
}
