import React from 'react'
import { useAppRouter } from 'app/AppRouter'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import './index.css'

const container = document.getElementById('root')!
const root = createRoot(container)

const AppWithRouter = () => {
  const router = useAppRouter()
  return <RouterProvider router={router} />
}

root.render(
  <React.StrictMode>
    <AppWithRouter />
  </React.StrictMode>
)
