import { LatestUserAppointment, User } from 'types'
import { getVideoCallFlow, VideoCallStep } from '../types'

export const findNextStep = (
  currentIndex: number,
  user: User,
  latestUserAppointment: LatestUserAppointment
): VideoCallStep => {
  const videoCallFlow = getVideoCallFlow()
  const nextStep = videoCallFlow[currentIndex + 1]

  if (!nextStep) {
    return videoCallFlow[currentIndex]
  }

  if (nextStep.completedResolver(user, latestUserAppointment)) {
    return findNextStep(currentIndex + 1, user, latestUserAppointment)
  }

  return nextStep
}
