import { LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

export const auth: RouteObject[] = [
  {
    path: LocationPaths.Auth,
    lazy: async () => ({ Component: (await import('screens/Auth')).Auth })
  },
  {
    path: LocationPaths.SignIn,
    lazy: async () => ({ Component: (await import('screens/SignIn')).SignIn })
  },
  {
    path: LocationPaths.PasswordResetRequest,
    lazy: async () => ({
      Component: (await import('screens/ResetPasswordRequest')).ResetPasswordRequest
    })
  }
]
