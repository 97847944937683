import { DocumentNode } from 'graphql'
import { LocationPaths, VideoCallsPath } from 'location.types'
import has from 'lodash/has'
import { LazyComponentWithPreload, lazyPreload } from 'utils/lazyPreload'
import { surveyScreenPath } from 'utils/navigation'
import { getUserData } from 'utils/userData'
import {
  User,
  LatestUserAppointment,
  SurveysConfigKind,
  MobileAppFeature,
  ClientConfigDocument,
  InsurancePlansDocument,
  UserReferralPartner,
  InsuranceBerryStreetAppointmentStatus,
  UserApprovalRequestState
} from 'types'
import { PARTNER_ID_KEY } from './hooks'
import { isBookingAllowed } from './utils'
import { getVideoCallData, ineligiblePlanSelected } from './utils/videoCallsStorage'

export interface VideoCallStep {
  path: LocationPaths | VideoCallsPath
  location: string
  completedResolver: (user: User | null, latestAppointment: LatestUserAppointment | null) => boolean
  canNavigateBack: boolean
  routeComponent: LazyComponentWithPreload<() => JSX.Element | null>
  noPageAnimation?: boolean
  requiresStripe?: boolean
  preloadQueries?: DocumentNode[]
}

const ChooseHealthGoalScreen = lazyPreload(() =>
  import('screens/VideoCalls/ChooseHealthGoal').then((module) => ({
    default: module.ChooseHealthGoal
  }))
)

const ChooseInsurancePlanScreen = lazyPreload(() =>
  import('screens/VideoCalls/ChooseInsurancePlan').then((module) => ({
    default: module.ChooseInsurancePlan
  }))
)

const SubmitEmailAddressScreen = lazyPreload(() =>
  import('screens/VideoCalls/SubmitEmailAddress').then((module) => ({
    default: module.SubmitEmailAddress
  }))
)

const AddressFormScreen = lazyPreload(() =>
  import('screens/VideoCalls/AddressForm').then((module) => ({
    default: module.AddressForm
  }))
)

const VideoCallsSignUpScreen = lazyPreload(() =>
  import('screens/VideoCalls/SignUp').then((module) => ({
    default: module.SignUp
  }))
)

const FreeVideoCallScreen = lazyPreload(() =>
  import('screens/FreeVideoCall').then((module) => ({
    default: module.FreeVideoCall
  }))
)
const SubmitState = lazyPreload(() =>
  import('screens/VideoCalls/SubmitState').then((module) => ({
    default: module.SubmitState
  }))
)

const SurveyScreen = lazyPreload(() =>
  import('screens/Survey').then((module) => ({
    default: module.SurveyScreen
  }))
)

const ScheduleCallScreen = lazyPreload(() =>
  import('screens/ScheduleCall').then((module) => ({
    default: module.ScheduleCall
  }))
)

const CompletedScreen = lazyPreload(() =>
  import('screens/VideoCalls/FlowCompleted').then((module) => ({
    default: module.FlowCompleted
  }))
)

const AddPaymentMethodScreen = lazyPreload(() =>
  import('screens/VideoCalls/AddPaymentMethod').then((module) => ({
    default: module.AddPaymentMethod
  }))
)

const AddAppointmentAgendaScreen = lazyPreload(() =>
  import('screens/VideoCalls/AddAppointmentAgenda').then((module) => ({
    default: module.AddAppointmentAgenda
  }))
)

const HeardAboutNutrisenseScreen = lazyPreload(() =>
  import('screens/VideoCalls/HeardAboutNutrisense').then((module) => ({
    default: module.HeardAboutNutrisense
  }))
)

export const getVideoCallFlow: () => VideoCallStep[] = () => {
  const result: VideoCallStep[] = [
    {
      path: LocationPaths.VideoCalls,
      location: LocationPaths.VideoCalls,
      completedResolver: (user) => {
        if (user?.features.includes(MobileAppFeature.InsuranceCoveredVideoCalls)) {
          return true
        }
        const videoCallData = getVideoCallData()
        return !!videoCallData?.health_goal
      },
      canNavigateBack: false,
      routeComponent: ChooseHealthGoalScreen
    }
  ]

  const emailAddressStep: VideoCallStep = {
    path: VideoCallsPath.EmailAddress,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.EmailAddress}`,
    completedResolver: (user) => {
      const userData = getUserData()
      return !!user?.email || !!userData?.email
    },
    canNavigateBack: true,
    routeComponent: SubmitEmailAddressScreen
  }

  const stateStep: VideoCallStep = {
    path: VideoCallsPath.State,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.State}`,
    completedResolver: (user) => {
      const userData = getUserData()
      return !!user?.address?.state || !!userData?.state
    },
    canNavigateBack: true,
    routeComponent: SubmitState
  }

  const signUpStep: VideoCallStep = {
    path: VideoCallsPath.SignUp,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.SignUp}`,
    completedResolver: (user) => !!user,
    canNavigateBack: true,
    routeComponent: VideoCallsSignUpScreen
  }

  const addressFormStep: VideoCallStep = {
    path: VideoCallsPath.AddressForm,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.AddressForm}`,
    completedResolver: (user) => !!user?.address,
    canNavigateBack: false,
    routeComponent: AddressFormScreen,
    preloadQueries: [ClientConfigDocument]
  }

  const freeVideoCallStep: VideoCallStep = {
    path: VideoCallsPath.FreeVideoCall,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.FreeVideoCall}`,
    completedResolver: () => {
      return false
    },
    canNavigateBack: false,
    routeComponent: FreeVideoCallScreen
  }

  const scheduleCallStep: VideoCallStep = {
    path: VideoCallsPath.ScheduleCall,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.ScheduleCall}`,
    completedResolver: (user, latestAppointment) => {
      if (isBookingAllowed(user)) {
        return (
          !!latestAppointment &&
          latestAppointment.status !== InsuranceBerryStreetAppointmentStatus.SchedulingPending
        )
      } else {
        const videoCallData = getVideoCallData()
        return !!videoCallData?.reserved_appointment
      }
    },
    canNavigateBack: true,
    routeComponent: ScheduleCallScreen
  }

  const addAppointmentAgendaStep: VideoCallStep = {
    path: VideoCallsPath.AddAppointmentAgenda,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.AddAppointmentAgenda}`,
    completedResolver: (_user, latestAppointment) => {
      if (
        !!latestAppointment &&
        latestAppointment.status !== InsuranceBerryStreetAppointmentStatus.SchedulingPending
      ) {
        return true
      }
      const videoCallData = getVideoCallData()
      return has(videoCallData, 'appointment_agenda')
    },
    canNavigateBack: true,
    routeComponent: AddAppointmentAgendaScreen
  }

  const completedStep: VideoCallStep = {
    path: VideoCallsPath.Completed,
    location: `${LocationPaths.VideoCalls}/${VideoCallsPath.Completed}`,
    completedResolver: () => {
      return false
    },
    canNavigateBack: false,
    routeComponent: CompletedScreen
  }

  const partner = localStorage.getItem(PARTNER_ID_KEY)

  const isInfluencerFlow = partner === UserReferralPartner.Influencer

  if (isInfluencerFlow) {
    result.push(
      emailAddressStep,
      stateStep,
      signUpStep,
      freeVideoCallStep,
      scheduleCallStep,
      addAppointmentAgendaStep,
      addressFormStep,
      completedStep
    )
    return result
  }

  result.push(
    {
      path: VideoCallsPath.Insurance,
      location: `${LocationPaths.VideoCalls}/${VideoCallsPath.Insurance}`,
      completedResolver: (user) => {
        const videoCallData = getVideoCallData()

        return (
          user?.features.includes(MobileAppFeature.InsuranceCoveredVideoCalls) ||
          (!!videoCallData?.selected_insurance_plan && !ineligiblePlanSelected())
        )
      },
      canNavigateBack: true,
      routeComponent: ChooseInsurancePlanScreen,
      preloadQueries: [InsurancePlansDocument]
    },
    emailAddressStep,
    stateStep,
    scheduleCallStep,
    addAppointmentAgendaStep,
    signUpStep,
    {
      path: VideoCallsPath.Questionnaire,
      location: `${LocationPaths.VideoCalls}${surveyScreenPath(SurveysConfigKind.Insurance)}`,
      completedResolver: (user) => {
        if (!user) {
          return false
        }
        if (user.features.includes(MobileAppFeature.InsuranceCoveredVideoCalls)) {
          return true
        }
        const insuranceSurveyLink = user.surveyLinks.find(
          (surveyLink) => surveyLink.survey.kind === SurveysConfigKind.Insurance
        )
        if (!insuranceSurveyLink) {
          return false
        }

        return insuranceSurveyLink.finished
      },
      canNavigateBack: false,
      routeComponent: SurveyScreen,
      noPageAnimation: true
    },
    addressFormStep,
    {
      path: VideoCallsPath.AddPaymentMethod,
      location: `${LocationPaths.VideoCalls}/${VideoCallsPath.AddPaymentMethod}`,
      completedResolver: (user, latestAppointment) => {
        if (!user) {
          return false
        }
        const bookingWithCreditCardCompleted = !!user.paymentMethod?.stripeId && !!latestAppointment
        return user.referralPartner.schedulingWithoutPaymentMethod || bookingWithCreditCardCompleted
      },
      requiresStripe: true,
      canNavigateBack: false,
      routeComponent: AddPaymentMethodScreen
    },
    {
      path: VideoCallsPath.HeardAboutNutrisense,
      location: `${LocationPaths.VideoCalls}/${VideoCallsPath.HeardAboutNutrisense}`,
      completedResolver: (user) => {
        // User came from DPP flow
        if (user?.lastDppApprovalRequest?.state === UserApprovalRequestState.Approved) {
          return true
        }
        const videoCallData = getVideoCallData()
        return !!videoCallData?.heard_about_us
      },
      canNavigateBack: false,
      routeComponent: HeardAboutNutrisenseScreen
    },
    completedStep
  )

  return result
}
